import React, { useState } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { FormControl, NativeSelect, InputLabel,
  Input, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Img from 'gatsby-image'

import lojasStyles from './lojas.module.scss'
import Layout from '../components/layout'
import SEO from '../components/seo'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    flex: '1 120px',
    '&:nth-of-type(2)': {
      flex: '1 200px'
    },
  },
  
  textField: {
    margin: theme.spacing(1),
    flex: '1 200px'
  }
}))

const LojasPage = () => {
  const data = useStaticQuery(graphql`
    query {
      lojas: allMarkdownRemark (
        sort: { fields: frontmatter___nome, order: ASC},
        filter: { fileAbsolutePath: { regex: "\/lojas/" } }
      ){
        edges {
          node {
            frontmatter {
              nome
              endereco
              bairro
              zona
              cidade
              estado
              cep
              telefone
              site
              loja
              distribuidora
              instalacao
            }
            fields {
              slug
            }
          }
        }
      },
      lojaImagem: file(relativePath: { eq: "skyline-sao-paulo.jpg" }) {
        childImageSharp {
          fluid (quality: 50, maxWidth: 1920){
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)


  const edges = data.lojas.edges
  
  // Criar dados dos filtros
  const reducer = (accumulator, currentValue) => {
    const { cidade, estado, zona } = currentValue.node.frontmatter
    const cidadesAccumulator = accumulator.cidades
    const estadosAccumulator = accumulator.estados
    const zonasAccumulator = accumulator.zonas
    if (!cidadesAccumulator.includes(cidade) && cidade !== '') {
      cidadesAccumulator.push(cidade)
    }
    if (!estadosAccumulator.includes(estado) && estado !== '') {
      estadosAccumulator.push(estado)
    }
    if (!zonasAccumulator.includes(zona) && zona !== '') {
      zonasAccumulator.push(zona)
    }
    return {
      cidades: cidadesAccumulator,
      estados: estadosAccumulator,
      zonas: zonasAccumulator
    }
  }
  const dadosFiltros = edges.reduce(reducer,
    {
      cidades: [],
      estados: [],
      zonas: []
    }
  )
  const { cidades, estados, zonas } = dadosFiltros
  const [cidadeFiltro, setCidadeFiltro] = useState('')
  const [estadoFiltro, setEstadoFiltro] = useState('')
  const [zonaFiltro, setZonaFiltro] = useState('')
  const [nomeFiltro, setNomeFiltro] = useState('')

  // Filtrar os dados das lojas
  const lugaresFiltrados = edges.filter((edge) => {
    const { cidade, estado, nome, zona } = edge.node.frontmatter
    let isCidadeOk = true
    let isEstadoOk = true
    let isZonaOk = true
    let isNomeOk = true
    if (cidade !== cidadeFiltro && cidadeFiltro !== '') {
      isCidadeOk = false
    }
    if (estado !== estadoFiltro && estadoFiltro !== '') {
      isEstadoOk = false
    }
    if (zona !== zonaFiltro && zonaFiltro !== '') {
      isZonaOk = false
    }
    if(!nome.toLowerCase().includes(nomeFiltro.toLowerCase()) && nomeFiltro !== '') {
      isNomeOk = false
    }

    return isCidadeOk && isEstadoOk && isZonaOk && isNomeOk
  })

  const classes = useStyles();

  return (
    <Layout>
      <SEO title="Onde Encontrar" />
      <div className={lojasStyles.mainContent}>
        <div className={lojasStyles.lojaTexto}>
          <h1>
            Onde Encontrar
          </h1>
          <p>Confira as lojas e distribuidoras que vendem produtos Cerruns.</p>
        </div>
      </div>
      <Img fluid={data.lojaImagem.childImageSharp.fluid} />

      <div className={lojasStyles.mainContent}>
        <div className={lojasStyles.barraFiltros}>
          <form 
            className={lojasStyles.formFiltros}
            onSubmit={(event) => event.preventDefault()}
          >
            <TextField
              id="nome-filtro"
              label="Buscar nome"
              className={classes.textField}
              value={nomeFiltro}
              onChange={(event) => setNomeFiltro(event.target.value)}
              margin="normal"
            />
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="cidade-native-label-placeholder">
                Cidade
              </InputLabel>
              <NativeSelect
                value={cidadeFiltro}
                onChange={(event) => setCidadeFiltro(event.target.value)}
                input={<Input name="cidade" id="cidade-native-label-placeholder" />}
              >
                <option value="">---</option>
                {cidades.sort().map((cidade) => <option value={cidade} key={cidade}>{cidade}</option>)}
              </NativeSelect>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="zona-native-label-placeholder">
                Zona
              </InputLabel>
              <NativeSelect
                value={zonaFiltro}
                onChange={(event) => setZonaFiltro(event.target.value)}
                input={<Input name="zona" id="zona-native-label-placeholder" />}
              >
                <option value="">---</option>
                {zonas.sort().map((zona) => <option value={zona} key={zona}>{zona}</option>)}
              </NativeSelect>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="estado-native-label-placeholder">
                Estado
              </InputLabel>
              <NativeSelect
                value={estadoFiltro}
                onChange={(event) => setEstadoFiltro(event.target.value)}
                input={<Input name="estado" id="estado-native-label-placeholder" />}
              >
                <option value="">---</option>
                {estados.sort().map((estado) => <option value={estado} key={estado}>{estado}</option>)}
              </NativeSelect>
            </FormControl>
          </form>

        </div>
      </div>

      <div className={lojasStyles.mainContent}>
        <ul className={lojasStyles.lojas}>
          {
            lugaresFiltrados.map((lugar) => {
              const node = lugar.node
              const {
                nome,
                endereco,
                bairro,
                cidade,
                estado,
                cep,
                telefone,
                site,
                loja,
                distribuidora,
                instalacao
              } = node.frontmatter
              return (
                <li key={node.fields.slug}>
                  <h3 className={lojasStyles.nome}>
                    {
                      site ?
                        <OutboundLink href={site} target="_blank" rel="noopener noreferrer">
                          {nome}
                        </OutboundLink>
                        :
                        nome
                    }
                  </h3>
                  {loja && <div className={lojasStyles.chipLoja}>loja</div>}
                  {distribuidora && <div className={lojasStyles.chipDistribuidora}>distribuidora</div>}
                  {instalacao && <div className={lojasStyles.chipInstalacao}>instalação</div>}
                  
                  <p>
                    {endereco && `${endereco}, `}
                    {bairro && `${bairro}, ` }
                    {cidade && `${cidade}, `}
                    {estado && `${estado}`}
                    {cep && `, ${cep}`}
                    <br/>
                    {telefone}
                  </p>
                </li>
              )
            })
          }
        </ul>
        
        <p className={lojasStyles.obs}>
          Caso você tenha uma loja ou distribuidora que não está nesta lista, por favor entre em <Link to="/contato">contato</Link> conosco.
        </p>

      </div>
    </Layout>
  )

}

export default LojasPage